/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CreativeStatus {
    DRAFT = 'draft',
    IN_REVIEW = 'in-review',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    REMOVED = 'removed',
}
