/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdGroupTriggerEngagement {
    VIEW = 'view',
    CLICK = 'click',
    CUSTOM_DYNAMIC = 'custom-dynamic',
    UN_OPEN = 'un-open',
    EMAIL_OPEN = 'email-open',
    EMAIL_CLICK = 'email-click',
}
