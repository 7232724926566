import { PxSorting, SortingOrder } from '../generated'

const apiDirectionMap: Record<'asc' | 'desc' | 'none' | '', SortingOrder | undefined> = {
  'asc': SortingOrder.ASCENDING,
  'desc': SortingOrder.DESCENDING,
  'none': undefined,
  '': undefined,
}

export const buildSorting = <K extends string>(
  { sortColumn, sortDirection }: { sortColumn: K | undefined; sortDirection: 'asc' | 'desc' | 'none' | '' },
  keyMap: Partial<Record<K, string>> = {},
): PxSorting => {
  return sortColumn
    ? {
        column: keyMap[sortColumn] ?? sortColumn,
        order: apiDirectionMap[sortDirection],
      }
    : {}
}
