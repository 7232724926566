/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdGroupStatus {
    ACTIVE = 'active',
    REMOVED = 'removed',
    INACTIVE = 'inactive',
    DRAFT = 'draft',
}
