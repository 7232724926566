/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CreativeType {
    FACEBOOK_SINGLE_IMAGE = 'facebook-single-image',
    FACEBOOK_CAROUSEL = 'facebook-carousel',
    FACEBOOK_CANVAS_COLLECTIONS = 'facebook-canvas-collections',
    FACEBOOK_SINGLE_VIDEO = 'facebook-single-video',
}
