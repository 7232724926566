/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DirectSoldAdGroupType {
    IMPRESSION_GOAL = 'impression-goal',
    SHARE_OF_VOICE = 'share-of-voice',
    RUN_OF_SITE = 'run-of-site',
    PLD_ONLY = 'pld-only',
}
