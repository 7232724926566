/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdGroupSplitConditionField {
    INVENTORY_TYPE = 'inventory_type',
    DEVICE_TYPE = 'device_type',
    PREDICTED_IAB_VIEW_RATE = 'predicted_iab_view_rate',
    INVENTORY_URL_LIST = 'inventory_url_list',
}
