/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UniversalPixelConditionType {
    VIEW_DOMAIN = 'view-domain',
    VIEW_PATH = 'view-path',
    VIEW_QUERY_STRING = 'view-query-string',
    VIEW_URL = 'view-url',
    VIEW_ANY_PAGE = 'view-any-page',
}
