/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CreativeRotationType {
    AUTO = 'auto',
    EVEN = 'even',
    WEIGHTED = 'weighted',
    INHERITED = 'inherited',
}
