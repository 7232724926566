/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DimensionType {
    ALL_TIME = 'all-time',
    DAILY = 'daily',
    HOURLY = 'hourly',
    LAST7_DAYS = 'last7-days',
    LAST30_DAYS = 'last30-days',
    LAST90_DAYS = 'last90-days',
    MONTHLY = 'monthly',
    BEFORE_LAST7_DAYS = 'before-last7-days',
    BEFORE_LAST30_DAYS = 'before-last30-days',
    BEFORE_LAST90_DAYS = 'before-last90-days',
    QUARTERLY = 'quarterly',
    WEEKLY = 'weekly',
    YEARLY = 'yearly',
}
