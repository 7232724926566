/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UniversalPixelConditionComparison {
    CONTAINS = 'contains',
    NOT_CONTAINS = 'not-contains',
    EQUALS = 'equals',
    NOT_EQUALS = 'not-equals',
}
