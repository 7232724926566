/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdGroupSplitConditionValue {
    DESKTOP = 'desktop',
    TABLET = 'tablet',
    MOBILE = 'mobile',
    CTV = 'ctv',
    APP = 'app',
    WEB = 'web',
}
