/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LocationSubType {
    STATE = 'state',
    CITY = 'city',
    DMA = 'dma',
    POSTAL_CODE = 'postal-code',
    LOCATION_GROUP = 'location-group',
}
